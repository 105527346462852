<template>
  <div class="homePage">
    <div class="top  sc-width">
      <div class="return">RETURN</div>
      <div class="search">
        <div class="input">
          <input
            type="text"
            v-on:keyup.enter="handleSearch"
            v-model="filter.name"
            placeholder="Search for a document..."
          />
        </div>
        <div class="button" @click="handleSearch">SEARCH</div>
      </div>
    </div>
    <div class="main sc-width">
      <div class="title  sc-width">DOWNLOAD HISTORY</div>
      <div class="content sc-width">
        <div class="c-title">
          <div class="name">File Name</div>
          <div class="action">Action</div>
        </div>
        <div class="diviede"></div>
        <div class="no-data empty" v-if="list.length === 0">No Data</div>
        <div class="c-content" v-else>
          <div class="item" v-for="item in list" :key="item.file.id">
            <div class="file">{{ item.file.name }}</div>
            <div class="action">
              <a :href="item.file.url" target="_blank">View Document</a>
            </div>
          </div>
        </div>
        <div class="page">
          <Page
            transfer
            :page-size="limit"
            :page-size-opts="[10, 15, 20]"
            :total="total"
            show-sizer
            show-elevator
            show-total
            @on-change="pageChange"
            @on-page-size-change="pageSizeChange"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/api";
const { _downloadHistory } = api;
export default {
  computed: {},
  components: {},
  data() {
    return {
      modType: "card",
      page: 1,
      limit: 10, // must in 5-20
      total: 0,
      list: [],
      loading: false,
      companyCol: [
        {
          title: "File Name",
          render: (h, params) => {
            return h("span", params.row.file.name);
          }
        },
        {
          title: "Action",
          key: "action",
          width: 200,
          align: "center",
          slot: "action"
        }
      ],
      filter: {
        name: ""
      }
    };
  },
  // created:{
  //
  // },
  mounted() {
    this.getDownloadHistory();
  },
  methods: {
    handleSearch() {
      this.page = 1;
      this.getDownloadHistory();
    },

    pageChange(page) {
      this.page = page;
      this.getDownloadHistory();
    },

    pageSizeChange(limit) {
      this.limit = limit;
      this.getDownloadHistory();
    },

    getDownloadHistory() {
      this.loading = true;
      this.list = [];
      _downloadHistory({
        page: this.page,
        limit: this.limit,
        ...this.filter
      })
        .then(response => {
          const { data } = response;
          this.list = data.data;
          this.total = data.total;
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    }
  },
  filters: {}
};
</script>

<style lang="less" scoped>
@media (min-width: 1600px) {
}
@media (max-width: 1600px) and (min-width: 1400px) {
}
@media (max-width: 1400px) and (min-width: 1200px) {
}
@media (max-width: 1200px) {
}
.homePage {
  background: #ffffff;
  margin-top: 120px;
}
.sc-width {
  margin: 0 auto;
}

.top {
  display: flex;
  justify-content: space-between;
  margin: 35px auto 35px auto;
  .return {
    width: 140px;
    height: 40px;
    background: #ef7f21;
    border-radius: 5px;
    font-size: 20px;

    font-weight: 500;
    color: #ffffff;
    line-height: 18px;
    text-align: center;
    padding-top: 10px;
  }
  .search {
    display: flex;
    .input {
      width: 800px;
      height: 40px;
      background: #ffffff;
      border: 1px solid #ef7f21;
      padding-left: 10px;
      input {
        width: 100%;
        height: 100%;
        outline: none;
        border: none;
      }
    }
    .button {
      width: 140px;
      height: 40px;
      background: #ef7f21;
      /*border-radius: 5px;*/
      font-size: 18px;

      font-weight: 500;
      color: #ffffff;
      line-height: 18px;
      text-align: center;
      padding-top: 11px;
      border-top-right-radius: 5px;
      border-top-right-radius: 5px;
    }
  }
}
.empty {
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 25px;
}
.title {
  height: 90px;
  background: linear-gradient(90deg, #a25d72, #2d3b81);
  opacity: 0.9;
  border-radius: 5px;
  font-size: 28px;

  font-weight: 700;
  color: #ffffff;
  line-height: 18px;
  padding: 36px 0 0 50px;
}
.content {
  .c-title {
    display: flex;
    justify-content: space-between;
    margin: 40px 60px 20px 50px;
    .name {
      font-size: 24px;

      font-weight: 500;
      color: #323538;
      line-height: 18px;
    }
    .action {
      font-size: 24px;

      font-weight: 500;
      color: #323538;
      line-height: 18px;
    }
  }
  .diviede {
    width: 1400px;
    height: 1px;
    background: #999999;
    margin: 0 auto;
  }
  .c-content {
    .item {
      display: flex;
      height: 60px;
      justify-content: space-between;
      .file {
        font-size: 16px;

        font-weight: 500;
        color: #323538;
        line-height: 18px;
        padding: 23px 0 0 70px;
      }
      .action {
        width: 140px;
        height: 26px;
        background: #ef7f21;
        border-radius: 5px;
        font-size: 14px;

        font-weight: 500;
        color: #ffffff;
        line-height: 18px;
        text-align: center;
        padding-top: 4px;
        margin: 16px 20px 0 0;
      }
    }
  }
  .active {
    background: #fcf7f2;
  }
}
.page {
  text-align: center;
  padding-bottom: 20px;
}
.main {
  box-shadow: 0 2px 16px grey, 0 0 1px grey, 0 0 1px grey;
  margin-bottom: 50px;
}
</style>
